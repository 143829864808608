<template>
  <div id="businessLayout">
    <div>
      <v-app-bar
        v-if="businessLayout && businessLayoutState?.showAppBar"
        app
        :color="businessLayout.appBar.color"
        :height="businessLayout.appBar.height"
      >
        <v-toolbar-title
          class="font-weight-black headline"
          v-if="businessLayout.appBar.title"
        >
          {{ businessLayout.appBar.title }} {{ draftPublishedState }}
        </v-toolbar-title>
      </v-app-bar>

      <v-main fluid fill-height style="overflow-x: hidden">
        <router-view
          @syncBusinessModel="syncBusinessModel"
          @editCompLayout="editComponent"
          @changeDraftState="changeDraftState"
          @syncDraftTemplate="syncDraftTemplate"
          ref="businessView"
        />
      </v-main>

      <voeter :showLegal="false" v-if="businessLayoutState?.showAppFooter" />
    </div>
    <v-navigation-drawer
      v-if="businessLayoutState && editMode"
      v-model="editingTemplateSettings"
      temporary
      absolute
      right
      width="324px"
      style="z-index: 100"
    >
      <v-card height="100%" flat>
        <v-form class="d-flex flex-column" style="height: 100%">
          <v-card-title class="px-3">
            <span>Template Settings</span>
            <v-spacer />
            <v-btn
              fab
              x-small
              dark
              depressed
              color="grey lighten-1"
              :href="previewUrl"
              target="previewWindow"
              class="mr-1"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              dark
              depressed
              color="grey lighten-1"
              @click="editingTemplateSettings = !editingTemplateSettings"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-actions class="pa-3">
            <v-row>
              <v-col cols="4">
                <v-btn
                  block
                  :disabled="draftPublishedState === 'published'"
                  :dark="draftPublishedState !== 'published'"
                  small
                  depressed
                  color="grey lighten-1"
                  @click="cancelCompChanges"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  block
                  dark
                  small
                  depressed
                  color="orange lighten-2"
                  @click="saveDraftUpdate"
                >
                  Save
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  block
                  small
                  depressed
                  :disabled="!valid || draftPublishedState === 'published'"
                  color="primary"
                  @click="publishUpdate"
                >
                  Publish
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-text>
            <v-checkbox
              v-model="editingBusinessLayoutState.showAppBar"
              label="Show App Bar"
              hide-details
              class="mb-4"
            >
            </v-checkbox>
            <v-text-field
              v-model="editingBusinessLayout.appBar.title"
              label="App Bar Title"
            ></v-text-field>
            <v-text-field
              v-model="editingBusinessLayout.appBar.height"
              label="App Bar Height"
              type="number"
            ></v-text-field>
            <v-label class="text-caption">App Bar Color</v-label>
            <v-color-picker
              v-model="editingBusinessLayout.appBar.color"
              label="App Bar Color"
            ></v-color-picker>
            <v-checkbox
              v-model="editingBusinessLayoutState.showAppFooter"
              label="Show App Footer"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="editingBusinessLayoutState.showNavDrawer"
              label="Show App Drawer"
              hide-details
            ></v-checkbox>
          </v-card-text>
        </v-form>
      </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="editingComp"
      v-model="editingComp"
      right
      app
      width="324px"
      style="z-index: 99"
    >
      <v-card height="100%" flat>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="publishUpdate"
          class="d-flex flex-column"
          style="height: 100%"
        >
          <v-card-title class="px-3">
            <span>{{ formTitle }}</span>
            <v-spacer />
            <v-btn
              fab
              x-small
              dark
              depressed
              color="grey lighten-1"
              :href="previewUrl"
              target="previewWindow"
              class="mr-1"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              dark
              depressed
              color="grey lighten-1"
              @click="closeEditCompNavigatonDrawer"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-actions class="pa-3">
            <v-row>
              <v-col cols="4">
                <v-btn
                  block
                  :disabled="draftPublishedState === 'published'"
                  :dark="draftPublishedState !== 'published'"
                  small
                  depressed
                  color="grey lighten-1"
                  @click="cancelCompChanges"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  block
                  dark
                  small
                  depressed
                  color="orange lighten-2"
                  @click="saveDraftUpdate"
                >
                  Save
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  block
                  small
                  depressed
                  :disabled="!valid || draftPublishedState === 'published'"
                  color="primary"
                  @click="publishUpdate"
                >
                  Publish
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

          <!-- Admin Component Properties Editor -->
          <component
            v-if="!selectComponent"
            :is="editingCompImport"
            :businessModel="business"
            :currentRoutePath="currentRoutePath"
            :component="editingComponent"
            @updateComponent="updateComponentData"
          ></component>

          <v-card-text v-else class="text-center">
            Please Select a Component to Edit
          </v-card-text>
        </v-form>
      </v-card>
    </v-navigation-drawer>

    <v-fab-transition v-if="editMode">
      <v-btn
        v-show="showTemplateEditingFab"
        fab
        x-small
        fixed
        bottom
        left
        class="mb-9"
        @click="editTemplateSettings"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import Footer from "@/components/Footer";
import store from "@/store";

export default {
  name: "BusinessLayout",

  data: () => ({
    valid: false,
    showTemplateEditingFab: true,
    editingTemplateSettings: false,

    editingBusinessLayout: {
      appBar: {
        title: "Business Layout",
        height: 64,
        color: "primary",
      },
      navDrawer: null,
      bottomToolbar: null,
    },
    editingBusinessLayoutState: {
      showAppBar: false,
      showAppFooter: false,
      showNavDrawer: false,
    },

    editingComp: false,
    editingCompIndex: "",
    editingCompImport: null,
    business: null,
    draftPublishedState: "published",
    selectComponent: true,
    syncedDraftTemplate: null,
  }),

  components: {
    voeter: Footer, //footer is reserved, using something else
  },

  computed: {
    editMode() {
      // console.log("Edit Mode:", this.$route);
      return this.$route.meta.editMode === true;
    },
    previewUrl() {
      // Check if the domain includes 'localhost'
      const protocol = this.business?.domain.includes("localhost")
        ? "http"
        : "https";
      return `${protocol}://${this.business.domain}`;
    },
    ...mapState({
      businessLayout: (state) => state.businessLayout,
      businessLayoutState: (state) => state.businessLayoutState,
    }),
    // showAppBar() {
    //   return this.businessLayoutState.showAppBar;
    // },

    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },

    // disableSaveDraft() {
    //   const draftExists = !!this.business.draftTemplate;

    //   const equalDrafts = _.isEqual(
    //     this.syncedDraftTemplate,
    //     this.business.draftTemplate
    //   );

    //   if (
    //     !draftExists &&
    //     this.currentTemplate === "draftTemplate" &&
    //     !equalDrafts
    //   ) {
    //     // If there's no draft, disable the "Save Draft" button.
    //     return true;
    //   }

    //   if (!equalDrafts) {
    //     // If the synced draft is not equal to the business draft, enable the "Save Draft" button.
    //     return false;
    //   }

    //   if (equalDrafts && this.currentTemplate === "draftTemplate") {
    //     // If the synced draft is equal to the business draft and the current template is "draftTemplate", disable the "Save Draft" button.
    //     return true;
    //   }

    //   // By default, enable the "Save Draft" button.
    //   return false;
    // },

    // disableSaveDraft() {
    //   // const equaltoPublished = _.isEqual(
    //   //   this.business.publishedTemplate,
    //   //   this.business.draftTemplate
    //   // );
    //   const equalDrafts = _.isEqual(
    //     this.syncedDraftTemplate,
    //     this.business.draftTemplate
    //   );
    //   console.log("isEqual:", equalDrafts);
    //   const draftExists = !!this.business.draftTemplate;

    //   return (
    //     !draftExists || (!equalDrafts && draftExists) || !equalDrafts
    //     // (equalDrafts && this.currentTemplate === "draftTemplate")
    //   );
    // },

    businessTemplate() {
      return this.business[`${this.draftPublishedState}Template`];
    },

    businessComponentsArray() {
      return this.business[`${this.draftPublishedState}Components`];
    },

    editingComponent() {
      return this.businessComponentsArray.find(
        (component) => component.id === this.editingCompIndex
      );
    },

    currentRoutePath() {
      const subPath = this.$route.params.pathMatch;
      return subPath ? "/" + subPath : "/";
    },

    currentRoute() {
      if (this.businessTemplate) {
        const route = this.businessTemplate.routes.find(
          (route) => route.path === this.currentRoutePath
        );
        return route ? route : null;
      }
      return null;
    },

    formTitle() {
      return this.editingCompIndex !== ""
        ? `${this.editingComponent.name}`
        : "New Heading";
    },
  },

  mounted() {
    this.$vuetify.theme.light = true;
    // this.queryUserData();
  },

  methods: {
    editTemplateSettings() {
      this.editingBusinessLayout = _.cloneDeep(this.businessLayout);
      // console.log("Business Layout:", this.editingBusinessLayout);

      this.editingBusinessLayoutState = _.cloneDeep(this.businessLayoutState);
      // console.log("Business Layout State:", this.editingBusinessLayoutState);

      this.editingTemplateSettings = true;
      this.$vuetify.goTo(0);
    },
    syncDraftTemplate(val) {
      // console.log("Synced Draft:", val);
      this.syncedDraftTemplate = val;
    },

    // resetForm() {
    //   if (this.$refs.form) this.$refs.form.reset();

    //   this.valid = false;
    // },

    syncBusinessModel(businessModel) {
      // console.log("Syncing Business Model:", businessModel);

      if (!businessModel.draftTemplate) {
        this.draftPublishedState = "published";
      } else {
        this.draftPublishedState = "draft";
      }
      this.business = businessModel;
    },

    updateBusinessLayout() {
      // Clone the business object to ensure deep reactivity
      const mutableBusinessModel = _.cloneDeep(this.business);

      if (!mutableBusinessModel.draftTemplate) {
        mutableBusinessModel.draftTemplate = _.cloneDeep(
          mutableBusinessModel.publishedTemplate
        );
        mutableBusinessModel.draftComponents = _.cloneDeep(
          mutableBusinessModel.publishedComponents
        );
      }

      mutableBusinessModel.draftTemplate.layout = this.editingBusinessLayout;
      // console.log("Updated Business Layout:", this.editingBusinessLayout);

      const routeIndex = mutableBusinessModel.draftTemplate.routes.findIndex(
        (route) => route.path === this.currentRoutePath
      );

      mutableBusinessModel.draftTemplate.routes[routeIndex].layoutState =
        this.editingBusinessLayoutState;

      // Assign the modified business model back to trigger reactivity
      this.business = mutableBusinessModel;

      this.$refs.businessView.updateBusiness(mutableBusinessModel);

      store.commit("storeBusinessLayoutState", this.editingBusinessLayoutState);
      store.commit("storeBusinessLayout", this.editingBusinessLayout);
    },

    updateComponentData(updatedComponent) {
      // Clone the business object to ensure deep reactivity
      const mutableBusinessModel = _.cloneDeep(this.business);

      if (!mutableBusinessModel.draftTemplate) {
        mutableBusinessModel.draftTemplate = _.cloneDeep(
          mutableBusinessModel.publishedTemplate
        );
        mutableBusinessModel.draftComponents = _.cloneDeep(
          mutableBusinessModel.publishedComponents
        );
      }

      const currentComponentIndex =
        mutableBusinessModel.draftComponents.findIndex(
          (component) => component.id === updatedComponent.id
        );

      if (currentComponentIndex !== -1) {
        // Update the component in draftComponents array
        mutableBusinessModel.draftComponents[currentComponentIndex] =
          updatedComponent;
      }

      // Assign the modified business model back to trigger reactivity
      this.business = mutableBusinessModel;

      this.$refs.businessView.updateBusiness(mutableBusinessModel);
    },

    changeDraftState(state) {
      this.draftPublishedState = state;
    },

    saveDraftUpdate() {
      this.$refs.businessView.saveDraftUpdate();
    },

    publishUpdate() {
      this.$refs.businessView.publishUpdate();
    },

    cancelCompChanges() {
      this.$refs.businessView.cancelCompChanges();
      this.selectComponent = true;
    },

    closeEditCompNavigatonDrawer() {
      // console.log("Close Edit");
      this.editingComp = false;
      this.editingCompImport = null;
      this.editingCompIndex = "";
    },

    editComponent(component) {
      // console.log("Edit in Layout: Component:", component);
      this.editingCompIndex = component.id;

      this.editingCompImport = () =>
        import(
          `@/components/businessComps/${
            component.name.charAt(0).toLowerCase() + component.name.slice(1)
          }/B${component.name}Admin.vue`
        ).catch((error) => {
          console.error(`Failed to load component ${component.name}:`, error);
          // Handle the error appropriately
        });

      this.selectComponent = false;
      this.editingComp = true;
    },
  },
  watch: {
    editingBusinessLayoutState: {
      handler() {
        // console.log("Business Layout State Changed", val);
        this.updateBusinessLayout();
      },
      deep: true,
    },
    editingBusinessLayout: {
      handler() {
        // console.log("Business Layout Changed");
        this.updateBusinessLayout();
      },
      deep: true,
    },
  },
};
</script>

<style>
.v-dialog__content {
  max-width: 100vw;
}
</style>
