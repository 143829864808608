<template>
  <div
    id="businessView"
    v-if="computedComponents.length > 0"
    style="position: relative"
  >
    <component
      v-for="(componentData, index) in computedComponents"
      :is="componentData.component"
      :key="index"
      :componentProps="componentData.properties"
      @editComp="editComp(index)"
      :admin="true"
    ></component>
  </div>
</template>

<script>
import { Business } from "@/models";
import { Storage } from "@aws-amplify/storage";
import { DataStore } from "@aws-amplify/datastore";
import _ from "lodash";
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "BusinessView",

  data: () => ({
    initiallyLoaded: false,

    business: {
      id: "",
      image: "",
    },
    businessSubscription: null,
    syncedBusinessModel: null,
    draftPublishedState: "published",

    // editingCompIndex: -1,
  }),

  computed: {
    ...mapState({
      appLoading: (state) => state.appLoading,
      profileID: (state) => state.profile.id,
    }),

    businessTemplate() {
      return this.business[`${this.draftPublishedState}Template`];
    },

    businessComponentsArray() {
      return this.business[`${this.draftPublishedState}Components`];
    },

    businessViewStyle() {
      // const businessTemplate = this.business[this.draftPublishedState ? "publishedTemplate" : "draftTemplate"];

      const globalCompSpacing = this.businessTemplate?.globalCompSpacing;
      return globalCompSpacing ? "gap: " + globalCompSpacing : "";
    },

    currentRoutePath() {
      const subPath = this.$route.params.pathMatch;
      return subPath ? "/" + subPath : "/";
    },

    currentRoute() {
      // const businessTemplate = this.business[this.draftPublishedState ? "publishedTemplate" : "draftTemplate"];

      if (this.businessTemplate) {
        const route = this.businessTemplate.routes.find(
          (route) => route.path === this.currentRoutePath
        );

        const currentRouteLayoutState = route?.layoutState
          ? route.layoutState
          : null;

        if (currentRouteLayoutState) {
          store.commit("storeBusinessLayoutState", currentRouteLayoutState);
        }
        return route ? route : null;
      }
      return null;
    },

    computedComponents() {
      if (this.currentRoute) {
        return this.currentRoute.components.map((componentId) => {
          const component =
            this.businessComponentsArray.find(
              (component) => component.id === componentId
            ) || null;

          if (!component) return [];

          return {
            component: () =>
              import(
                `@/components/businessComps/${
                  component.name.charAt(0).toLowerCase() +
                  component.name.slice(1)
                }/B${component.name}.vue`
              ).catch((error) => {
                console.error(
                  `Failed to load component ${component.name}:`,
                  error
                );
                // Handle the error appropriately
              }),
            properties: component.properties,
          };
        });
      }
      return [];
    },
  },

  async mounted() {
    try {
      if (!this.appLoading) store.commit("storeAppLoading", true);

      // console.log("this.$route: ", this.$route);

      await this.queryBusiness().catch((err) => {
        console.log("Error getting business: ", err);
      });
    } catch (error) {
      console.log(error);
    }

    if (!this.initiallyLoaded) {
      this.initiallyLoaded = true;
      if (this.appLoading) store.commit("storeAppLoading", false);
    }
  },

  watch: {
    currentRoute: {
      handler: function (val) {
        if (!val) {
          this.$router.push({ path: "/" });
        }
      },
      deep: true,
    },
  },

  methods: {
    cancelCompChanges() {
      try {
        // console.log("Cancel Comp Changes");
        delete this.business.draftTemplate;
        this.draftPublishedState = "published";
        this.$emit("syncBusinessModel", this.business);
      } catch (error) {
        console.error("Error cancelCompChanges():", error);
      }
    },

    async saveDraftUpdate() {
      try {
        await DataStore.save(
          Business.copyOf(this.syncedBusinessModel, (updateModel) => {
            updateModel.draftTemplate = this.business.draftTemplate;

            updateModel.draftComponents = this.business.draftComponents;
          })
        );
      } catch (error) {
        console.error("Error publishUpdate():", error);
      }
    },

    async publishUpdate() {
      try {
        await DataStore.save(
          Business.copyOf(this.syncedBusinessModel, (updateModel) => {
            updateModel.publishedTemplate = this.business.draftTemplate;
            updateModel.draftTemplate = null;

            updateModel.publishedComponents = this.business.draftComponents;
            updateModel.draftComponents = null;
          })
        );
      } catch (error) {
        console.error("Error publishUpdate():", error);
      }
    },

    updateBusiness(newBusinessModel) {
      this.business = newBusinessModel;
      this.$emit("changeDraftState", "draft");
      this.draftPublishedState = "draft";
      // console.log("Updated Business Model:", newBusinessModel);
    },

    editComp(componentIndex) {
      // this.editingCompIndex = componentIndex;

      const component = this.businessComponentsArray[componentIndex];

      this.$emit("editCompLayout", component);
    },

    async queryBusiness() {
      try {
        const businessId = this.$route.params.domain;
        this.businessSubscription = DataStore.observeQuery(Business, (vc) =>
          vc.domain("eq", businessId)
        ).subscribe(async (snapshot) => {
          const { items, isSynced } = snapshot;
          if (isSynced && items?.length > 0) {
            if (this.profileID !== items[0].profileID) {
              this.$router.push("/");
            }

            this.syncedBusinessModel = items[0];

            const localBusiness = _.cloneDeep(items[0]);

            // if (this.editingCompIndex > -1) {
            //   // this.$emit(
            //   //   "editCompLayout",
            //   //   this.businessComponentsArray[this.editingCompIndex]
            //   // );
            // } else {
            this.business = localBusiness;
            //   this.$emit("syncBusinessModel", this.business);
            // }

            if (localBusiness.logoKey) {
              this.business.image = await Storage.get(localBusiness.logoKey, {
                level: "public",
              });
            }

            this.$emit("syncBusinessModel", this.business);
            if (localBusiness.draftTemplate) {
              this.draftPublishedState = "draft";
              this.$emit("syncDraftTemplate", localBusiness.draftTemplate);
            } else {
              this.draftPublishedState = "published";
            }

            if (localBusiness.publishedTemplate.layout) {
              store.commit(
                "storeBusinessLayout",
                localBusiness.publishedTemplate.layout
              );
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
  },

  beforeDestroy() {
    if (this.businessSubscription) {
      this.businessSubscription.unsubscribe();
    }
  },
};
</script>
