<template>
  <div class="businesses">
    <v-container fluid>
      <TopToolbar @actionButtonClick="actionButtonClick" />
      <v-row
        no-gutters
        :v-if="!loadingBusinesses"
        style="margin-left: -4px; margin-right: -4px"
      >
        <div v-for="(business, i) in businesses" :key="i">
          <v-card flat class="mx-1 mb-2" rounded="xl" elevation="10">
            <div class="d-flex">
              <v-card-title
                class="text-h5 pb-0"
                style="width: -webkit-fill-available"
                >{{ business.businessName }}</v-card-title
              >
            </div>
            <v-card-text class="py-0">
              <span>{{ business.businessSlogan }}</span>
              <!-- <div v-if="vC.company" style="margin: 5px 0">
                <v-avatar :color="vC.themeColors.primaryColor" size="28">
                  <v-icon dense dark>mdi-home-account</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">{{ vC.company }}</span>
              </div>
              <div v-if="vC.role" style="margin: 5px 0">
                <v-avatar :color="vC.themeColors.primaryColor" size="28">
                  <v-icon dense dark>mdi-account</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">{{ vC.role }}</span>
              </div> -->
              <div v-if="business.contact.phone" style="margin: 10px 0">
                <v-avatar :color="business.themeColors.primaryColor" size="28">
                  <v-icon dense dark>mdi-phone</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">
                  <a
                    :href="hrefPhone(business.contact.phone.number)"
                    :style="'color:' + business.themeColors.primaryColor"
                  >
                    {{ business.contact.phone.number }}
                  </a>
                </span>
              </div>
              <div v-if="business.contact.email" style="margin: 5px 0">
                <v-avatar :color="business.themeColors.primaryColor" size="28">
                  <v-icon dense dark>mdi-email</v-icon>
                </v-avatar>
                <span style="margin-left: 10px"
                  ><a
                    :href="hrefEmail(business.contact.email)"
                    :style="'color:' + business.themeColors.primaryColor"
                  >
                    {{ business.contact.email }}
                  </a></span
                >
              </div>
            </v-card-text>
            <v-card-actions class="pt-0" style="justify-content: right">
              <v-btn
                depressed
                icon
                min-width="0"
                color="grey"
                dark
                @click="viewBusiness(business.domain)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                depressed
                icon
                min-width="0"
                color="grey"
                dark
                @click="editBusiness(business)"
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                depressed
                icon
                min-width="0"
                color="red"
                dark
                @click="deleteBusiness(business)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-actions
            >
          </v-card>
        </div>
      </v-row>
    </v-container>
    <v-dialog
      persistent
      v-model="addBusinessDialog"
      :style="
        screenWidth < 600
          ? 'width: 100%; margin: 0; overflow-x: hidden'
          : 'width: 90%; overflow-x: hidden'
      "
    >
      <v-card>
        <AddBusiness
          @closeBusinessDialog="closeBusinessDialog"
          v-if="addBusinessDialog"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="editBusinessDialog"
      :style="
        screenWidth < 600
          ? 'width: 100%; margin: 0; overflow-x: hidden'
          : 'width: 90%; overflow-x: hidden'
      "
    >
      <v-card>
        <AddBusiness
          @closeBusinessDialog="closeBusinessDialog"
          :businessProp="currentBusiness"
          v-if="editBusinessDialog"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Business } from "@/models";
import { Storage } from "@aws-amplify/storage";
import { DataStore } from "@aws-amplify/datastore";
import store from "@/store";
// import _ from "lodash";
import TopToolbar from "@/components/TopToolbar";
import AddBusiness from "@/components/AddBusiness";
import { mapState } from "vuex";

export default {
  name: "MyBusinessesView",

  components: {
    AddBusiness,
    TopToolbar,
  },

  data: () => ({
    addBusinessDialog: false,
    editBusinessDialog: false,
    businesses: null,
    currentBusiness: null,
    loadingBusinesses: true,
    businessSubscription: null,
  }),

  computed: {
    ...mapState({
      profileID: (state) => state.profile.id,
    }),
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
  },

  mounted() {
    // await this.queryBusinesses();

    store.commit("updateToolbarState", {
      title: "Businesses",
      actions: [
        {
          icon: "mdi-plus",
          toolTip: "Add a Business",
        },
      ],
      rightButton: null,
    });

    this.loadingBusinesses = false;
  },

  watch: {
    profileID: {
      handler: async function (val) {
        if (val !== "" && !this.businessSubscription) {
          await this.queryBusinesses();
        }
      },
      immediate: true,
    },
  },

  methods: {
    async deleteBusiness(business) {
      await DataStore.delete(business);
      await Storage.remove(business.logoKey);
    },

    viewBusiness(businessDomain) {
      this.$router.push(`/b/${businessDomain}`);
    },

    editBusiness(business) {
      this.currentBusiness = business;
      this.editBusinessDialog = true;
    },

    actionButtonClick(action) {
      if (action === 0) {
        this.addBusinessDialog = true;
      } else {
        console.log("else:", action);
      }
    },

    closeBusinessDialog() {
      this.editBusinessDialog = false;
      this.addBusinessDialog = false;
    },

    divStyle(color) {
      return "width: 100%; height: 5px; background-color:" + color;
    },

    hrefPhone(phone) {
      return "tel:" + phone;
    },

    hrefEmail(email) {
      return "mailto:" + email;
    },

    async queryBusinesses() {
      try {
        console.log("profileID", this.profileID);
        this.businessSubscription = DataStore.observeQuery(Business, (b) =>
          b.profileID("eq", this.profileID)
        ).subscribe(async (snapshot) => {
          const { items, isSynced } = snapshot;
          if (isSynced && items?.length > 0) {
            this.businesses = items;

            store.commit("hasBusiness");
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
  },

  beforeDestroy() {
    if (this.businessSubscription) {
      this.businessSubscription.unsubscribe();
    }
  },
};
</script>
