<template>
  <div>
    <v-snackbar bottom :value="updateExists" :timeout="-1" color="#288254">
      An update is available.
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="refreshApp">Update</v-btn>
      </template>
    </v-snackbar>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      x-small
      fixed
      bottom
      right
      color="primary"
      @click="$vuetify.goTo(0)"
      class="mb-8"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-footer app>
      <span class="pr-9"> &copy; {{ new Date().getFullYear() }} Bizpoke.</span>

      <div class="text-center" v-if="showLegal">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small text color="primary" dark v-bind="attrs" v-on="on">
              Legal Information
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(doc, di) in docs" :key="di">
              <v-btn small text :to="doc.route">
                {{ doc.title }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-spacer></v-spacer>
      <span class="font-weight-bold text-caption">v1.0.31</span>
      <v-spacer></v-spacer>
      <v-btn
        href="https://www.go-app.co.za/app-development/"
        small
        text
        target="_blank"
        >Powered by go!App
      </v-btn>
      <v-btn icon @click="refreshApp" v-if="updateExists === true">
        <v-icon dense color="primary">mdi-refresh</v-icon>
      </v-btn>
      <cookie-law theme="blood-orange" class="mb-10">
        <v-row align="center" slot-scope="props">
          <v-col class="grow">
            This site uses cookies. By continuing to browse the site, you are
            agreeing to our use of cookies.
            <v-dialog v-model="cookieDialog" width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" text>
                  Read More
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="primary--text">COOKIES POLICY</span>
                </v-card-title>
                <v-card-text>
                  <p>
                    Cookies are files stored in your browser and are used by
                    most websites to help personalise your web experience. Some
                    features of this website will not function if you do not
                    allow cookies or have cookies disabled.
                  </p>
                  <p>
                    For the best experience using this site, we recommend using
                    an up-to-date browser with JavaScript enabled and accept
                    cookies.
                  </p>
                  <h2 class="subtitle-1">OVERALL</h2>
                  <p>
                    This website makes use of essential session cookies to
                    provide you the visitor with a service. We have
                    automatically set a session cookie so you can use this
                    website and service. If you do not want this cookie set,
                    then please follow the link at the bottom of this page or
                    manually delete the session cookie at any time by following
                    the instructions in your browser. By continuing to use this
                    website you give implied consent that we can store other
                    cookies on your computer to provide you with this service.
                    We will store some third party cookies to provide us with
                    analytical data about who visits this website (anonymous
                    visitor data, we cannot personally identify you), how they
                    find us, and how long they use the site for. We do not use
                    any information to track you, or personally identify you, we
                    are only looking at summary data, always anonymous.
                  </p>
                  <p class="subtitle-2">Cookie information</p>
                  <h2 class="subtitle-1">SESSION COOKIE</h2>
                  <p>
                    We automatically set a session cookie in order for you to
                    use this website or service. This session cookie is
                    essential for the correct operation of this website and
                    service. If the session cookie is not available, you may not
                    be able to use some or all of the features of this website
                    or service.
                  </p>
                  <p>
                    The session cookie stores some basic information about your
                    current status such as whether you are logged in and
                    previous search information e.g. registration numbers and
                    filters.
                  </p>
                  <p>
                    A session cookie only lasts as long as your current browsing
                    session and is typically removed or deleted by your browser
                    when you stop using this website or service. You can
                    manually delete your session cookie at any time by following
                    the instructions in your browser.
                  </p>
                  <h2 class="subtitle-1">THIRD-PARTY COOKIES</h2>
                  <p>
                    This website makes use of Google Analytics. Google Analytics
                    sets a cookie which allows us to view anonymous visitor data
                    so we can improve this website or service to you. We
                    typically make use of the overall visitor counts, the search
                    parameters users used to find us and the amount of time a
                    user spent on the site. You can manually delete this cookie
                    at any time by following the instructions in your browser.
                  </p>
                  <h2 class="subtitle-1">BACKGROUND INFORMATION</h2>
                  <p>
                    The 2003 Regulations implemented a European Directive –
                    2002/58/EC – which is concerned with the protection of
                    privacy in the electronic communications sector. In 2009
                    this Directive was amended by Directive 2009/136/EC. This
                    included a change to Article 5(3) of the E-Privacy Directive
                    requiring consent for storage or access to information
                    stored on a subscriber or users terminal equipment – in
                    other words a requirement to obtain consent for cookies and
                    similar technologies.
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="cookieDialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col class="shrink">
            <v-btn color="primary" @click="props.accept">I accept</v-btn>
          </v-col>
        </v-row>
      </cookie-law>
    </v-footer>
  </div>
</template>

<script>
import update from "@/mixins/update";
import CookieLaw from "vue-cookie-law";

export default {
  name: "FooterComponent",
  data: () => ({
    fab: false,
    cookieDialog: false,
    docs: [
      { title: "Terms & Conditions", route: "/legal/terms-conditions" },
      { title: "Terms of Use", route: "/legal/terms-of-use" },
      { title: "Cookie Policy", route: "/legal/cookie-policy" },
      { title: "Privacy Policy", route: "/legal/privacy-policy" },
      {
        title: "Modern Slavery Statement",
        route: "/legal/modern-slavery-statement",
      },
      { title: "Quality Statement", route: "/legal/quality-statement" },
      { title: "WEEE Regulations", route: "/legal/weee-regulations" },
    ],
  }),
  props: {
    showLegal: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [update],
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
  },
  components: { CookieLaw },
};
</script>
