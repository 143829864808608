<template>
  <div class="profile">
    <v-col v-if="initiallyLoaded">
      <TopToolbar
        @rightButtonClick="saveProfile"
        @actionButtonClick="actionButtonClick"
      />

      <v-row no-gutters>
        <v-text-field
          v-model="profile.fullName"
          label="Name:"
          class="pr-2"
        ></v-text-field>
        <v-color-picker
          v-model="profile.primaryColor"
          mode="hexa"
        ></v-color-picker>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { Profile } from "@/models";
// import { Storage } from "@aws-amplify/storage";
import { DataStore } from "@aws-amplify/datastore";
import store from "@/store";
import _ from "lodash";
import TopToolbar from "@/components/TopToolbar";
// import Vuetify from "@/plugins/vuetify";
import { mapState } from "vuex";

export default {
  name: "MyProfileView",

  components: {
    TopToolbar,
  },

  data: () => ({
    initiallyLoaded: false,
    profile: {
      fullName: "",
      primaryColor: "#208b26",
    },
    profileModel: null,
    profileSubscription: null,
  }),

  computed: {
    // screenWidth() {
    //   return this.$vuetify.breakpoint.width;
    // },
    ...mapState({
      appLoading: (state) => state.appLoading,
    }),
  },

  async mounted() {
    try {
      if (!this.appLoading) store.commit("storeAppLoading", true);

      store.commit("updateToolbarState", {
        title: "Create Profile",
        actions: [],
        rightButton: {
          text: "Create",
        },
      });

      await this.queryProfile();
    } catch (error) {
      console.log(error);
    }

    if (!this.initiallyLoaded) {
      this.initiallyLoaded = true;
      if (this.appLoading) store.commit("storeAppLoading", false);
    }

    // if (this.profileModel) {

    // this.stateProp = {
    //   title: "My Profile",
    //   actions: [
    //     {
    //       icon: "mdi-delete",
    //       toolTip: "Delete profile",
    //     },
    //   ],
    //   rightButton:{
    //     text: "Save",
    //   }
    // };
    // } else {

    // this.stateProp = {
    //   title: "Create Profile",
    //   actions: [],
    //   rightButton:{
    //     text: "Create",
    //   }
    // };
    // }
  },

  methods: {
    actionButtonClick(action) {
      if (action === 0) {
        this.deleteProfile();
      } else {
        console.log("else:", action);
      }
    },

    async queryProfile() {
      try {
        this.profileSubscription = DataStore.observeQuery(Profile).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items?.length > 0) {
              this.profileModel = items[0];
              this.profile = _.cloneDeep(items[0]);

              store.commit("updateToolbarState", {
                title: "My Profile",
                actions: [
                  {
                    icon: "mdi-delete",
                    toolTip: "Delete profile",
                  },
                ],
                rightButton: {
                  text: "Save",
                },
              });

              store.commit("storeProfile", items[0]);
              // Vuetify.framework.theme.themes.light.primary =
              //   items[0].primaryColor;
              // Vuetify.framework.theme.themes.dark.primary =
              //   items[0].primaryColor;
            }
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async saveProfile() {
      try {
        if (this.profileModel) {
          await DataStore.save(
            Profile.copyOf(this.profileModel, (updateProfile) => {
              (updateProfile.fullName = this.profile.fullName),
                (updateProfile.primaryColor = this.profile.primaryColor);
            })
          );
        } else {
          await DataStore.save(
            new Profile({
              // userId: store.state.userId,
              fullName: this.profile.fullName,
              primaryColor: this.profile.primaryColor,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
    async deleteProfile() {
      try {
        await DataStore.delete(this.profileModel);
        this.profile = {
          fullName: "",
          primaryColor: "#208b26",
        };
        this.profileModel = null;
        store.commit("updateToolbarState", {
          title: "Create Profile",
          actions: [],
          rightButton: {
            text: "Create",
          },
        });
        store.commit("removeProfile");
        // Vuetify.framework.theme.themes.light.primary = "#208b26";
        // Vuetify.framework.theme.themes.dark.primary = "#208b26";
      } catch (err) {
        console.log(err);
      }
    },
  },

  beforeDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  },
};
</script>
